//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    isHitokoto: {
      type: Boolean,
      default: true
    },
    isShehui: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      guShi: {
        "content": "...",
        "origin": "...",
        "author": "...",
        "category": "..."
      },
      hitokoto: {
        "hitokoto": "...",
        "from": "...",
        "from_who": "..."
      }
    };
  },
  created: function created() {
    if (!this.isShehui) {
      if (this.isHitokoto) {
        this.getHitokoto();
      } else {
        this.getGuShi();
      }
    } else {
      this.hitokoto.from = "";
      this.hitokoto.from_who = "";
      this.sendShehui();
    }
  },
  methods: {
    sendShehui: function sendShehui() {
      var that = this;
      var xhr = new XMLHttpRequest();
      xhr.open('get', this.$constant.shehui);

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          var shehui = xhr.responseText;
          that.hitokoto.hitokoto = shehui.substring(1, shehui.length - 1);
        }
      };

      xhr.send();
    },
    getGuShi: function getGuShi() {
      var that = this;
      var xhr = new XMLHttpRequest();
      xhr.open('get', this.$constant.jinrishici);

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          that.guShi = JSON.parse(xhr.responseText);
        }
      };

      xhr.send();
    },
    getHitokoto: function getHitokoto() {
      var that = this;
      var xhr = new XMLHttpRequest();
      xhr.open('get', this.$constant.hitokoto);

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          that.hitokoto = JSON.parse(xhr.responseText);
        }
      };

      xhr.send();
    }
  }
};